import * as React from 'react';
import LogInForm from './components/LogInForm';
import { useMedia } from "hooks";

const TimezoneClock = () => {
    const [currentTime, setCurrentTime] = React.useState(new Date());
    const [timezone, setTimezone] = React.useState('');

    React.useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        try {
            setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        } catch (error) {
            console.error('Error al obtener la zona horaria:', error);
            setTimezone('No se pudo determinar');
        }

        return () => clearInterval(timer);
    }, []);

    return `${timezone} | ${currentTime.toLocaleString()}`;
};
const LogIn = () => {
    const { setInfo } = useMedia();

    React.useEffect(() => {
        document.title = "Iniciar sesión";
        setInfo({ clear: true });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <main className="login">
            <div className="login__wrapper">

                <div className="login__head">
                    <div className="login__head_logo"></div>
                    <h4 className="login__head_title">Registro de novedades</h4>
                </div>

                <LogInForm />

                <p className="p_version">Versión 1.0.{process.env.REACT_APP_BUILD_VERSION} | pruebas | <TimezoneClock /> </p>
            </div>
        </main>
    );
}

export default LogIn;